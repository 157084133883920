import React from "react";
import Box from "@material-ui/core/Box";
import Layout from "../../components/Layout";
import './Home.css'
import { Typography } from "@material-ui/core";

function Home() {
  return (
    <Layout>
      <Box p="40px" sx={{ textAlign: "center" }}>
        <Box className="hero">
          <p>ラップ<br />
            チャット<br />
            アプリ
          </p>
          {/* <img src="/assets/welcome-image.svg" alt="welcome" /> */}
        </Box>
        <div className="appinfo-wrapper">
          <img src="/assets/app-icon.png" alt="appIcon" />
          <p>アカペラップ</p>
        </div>
        <Box className="link-wrapper">
            <a id="appstore-button" href="https://apps.apple.com/jp/app/%E3%82%A2%E3%82%AB%E3%83%9A%E3%83%A9%E3%83%83%E3%83%97/id1632356739" target="_blank" rel="noreferrer">
              <img src="/assets/download-appstore-black.svg" />
            </a>
        </Box>
        <Box className="link-wrapper">
          <a
            href="https://twitter.com/acaperap?ref_src=twsrc%5Etfw"
            className="twitter-follow-button"
            data-show-count="false"
          >
            Follow @acaperap
          </a>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
          ></script>
        </Box>
        <Box className="link-wrapper">
          <a className="link" href="/contact">お問い合わせ</a>
        </Box>
        <Box className="link-wrapper">
          <a className="link" href="/terms-of-use">利用規約</a>
        </Box>
        <Box className="link-wrapper">
          <a className="link" href="/privacypolicy">プライバシーポリシー</a>
        </Box>
        <Box className="link-wrapper">
          <a className="link" target="_blank" rel="noreferrer" href="https://corp.welzen.co.jp">運営会社</a>
        </Box>
      </Box>
    </Layout>
  );
}

export default Home;
