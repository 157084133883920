import React from "react";
import Box from "@material-ui/core/Box";
import Layout from "../../components/Layout";
import './Home.css'
import './Community.css'

const Community: React.FC = () => {
  return (
    <Layout header>
      <Box p="8px 40px 20px" sx={{ textAlign: "center" }}>
        <Box className="community-hero">
          {/* <h3 className="community-hero-text">Comming soon...</h3> */}
          <p className="community-hero-sub-text">コミュニティは現在準備中です👷</p>
        </Box>
        <Box className="hero-img">
          <img src="/assets/app-hero-pc-sm.png" alt="welcome" />
        </Box>
        <Box className="link-wrapper">
          <a
            href="https://twitter.com/acaperap?ref_src=twsrc%5Etfw"
            className="twitter-follow-button"
            data-show-count="false"
          >
            Follow @acaperap
          </a>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
          ></script>
        </Box>
      </Box>
    </Layout>
  );
}

export default Community;
