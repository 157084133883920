import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import './firebaseConfig'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@material-ui/core';
 
const tagManagerArgs = {
  gtmId: 'GTM-M8M8DV5',
}
 
TagManager.initialize(tagManagerArgs)

const theme = createTheme({
  palette: {
    type: 'dark'
  }
})

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
