import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 20px',
    textAlign: 'left',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
  },
  headerTitle: {
    textDecoration: 'none',
    color: 'red',
    fontSize: '28px',
    width: '120px'
  },
}));

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link to="/" className={classes.link}>
              <img  className={classes.headerTitle} src="/acaperap-title-logo.svg" alt="" />
            </Link>
    </div>
  );
}

export default Header;
