import firebase from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: 'AIzaSyCMC70jqJRckh-wAUJnxjjW21q1Qi2b6dM',
  authDomain: 'acaperap-f2810.firebaseapp.com',
  projectId: 'acaperap-f2810',
  storageBucket: 'acaperap-f2810.appspot.com',
  messagingSenderId: '1061121911636',
  appId: '1:1061121911636:web:bd8e60f3fe948150cb76c1',
  measurementId: 'G-BKL7R5FPL0',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
