import React from "react";
import Box from "@material-ui/core/Box";
import Footer from "./FooterWeb";
import Header from "./HeaderWeb";

// eslint-disable-next-line react/prop-types
const Layout: React.FC<{ style?: React.CSSProperties, header?: boolean }> = ({ children, style, header = false }) => (
  <>
    <Box
      style={{ backgroundColor: '#282828', minHeight: 'calc(100vh)', position: 'relative', ...style }}
    >
      {header && <Header />}
      <Box maxWidth="768px" margin="auto" pb="60px">
      {children}
      </Box>
      <Footer />
    </Box>
  </>
);

export default Layout;
