import React from 'react';
import Box from '@material-ui/core/Box';

function Contact() {
  return (
    <Box>
     <style>
      {`
        * ::-webkit-scrollbar{
          display: none;
        }
          `}
      </style>
      <div style={{ width:'100%' , height: '100vh', overflow: 'auto', WebkitOverflowScrolling: 'touch' }}><iframe src="https://tayori.com/form/59df7bdaa90e473089ded5aa61e764c4fc210200/" width="100%" height="100%"></iframe></div>
    </Box>
  );
}

export default Contact;
