import React from 'react';
import './Footer.css'

function FooterWeb() {
  return (
    <div className='footer'>
          <p>
      {'Copyright © '}
      <a color="inherit" href="https://acaperap.com">
        Acaperap
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </p>
    </div>
  );
}

export default FooterWeb;
