import React, { useState, useEffect } from "react"
import Box from "@material-ui/core/Box"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Layout from "../../components/Layout"
import { Typography } from "@material-ui/core"
import { getAuth, applyActionCode, verifyPasswordResetCode, confirmPasswordReset, checkActionCode, sendPasswordResetEmail, Auth } from "firebase/auth"
import { RouteComponentProps } from "react-router-dom"

function AppAccount({ location }: RouteComponentProps<{ mode: string, oobCode: string }>) {
  const [message, setMessage] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const searchParams = new URLSearchParams(location.search)
  const mode = searchParams.get('mode') || '' // resetPassword, verifyEmail, recoverEmail
  const oobCode = searchParams.get('oobCode') || ''
  const [restoredEmail, setRestoredEmail] = useState('')
  useEffect(() => {
    if (!mode || !oobCode) {
      setMessage("無効なリクエストです。")
      return
    }

    const auth = getAuth()
    setIsLoading(true)

    switch (mode) {
      case "verifyEmail":
        handleVerifyEmail(auth, oobCode)
        break
      case "resetPassword":
        handleResetPassword(auth, oobCode)
        break
      case "recoverEmail":
        handleRecoverEmail(auth, oobCode)
        break
      default:
        setMessage("サポートされていないアクションです。")
        setIsLoading(false)
    }
  }, [mode, oobCode])

  const handleVerifyEmail = async (auth: Auth, oobCode: string) => {
    try {
      await applyActionCode(auth, oobCode)
      setMessage("メールアドレスの確認が完了しました。引き続きアプリをご利用ください。")
    } catch (error: any) {
      // console.log(`エラーが発生しました: ${error.message}`)
      setMessage(`エラーが発生しました。`)
    } finally {
      setIsLoading(false)
    }
  }

  // メールアドレス変更（復元メールを処理）
  const handleRecoverEmail = async (auth: Auth, code: string) => {
    try {
      const info = await checkActionCode(auth, code) // アクションコードの詳細を取得
      const restoredEmail = info.data.email
      setRestoredEmail(restoredEmail || '')
      await applyActionCode(auth, oobCode)
      setMessage(`ログインメールアドレスが ${restoredEmail} に戻されました。ログイン メールアドレスの変更をリクエストしていない場合は、誰かがあなたのアカウントにアクセスしようとしている可能性があります。今すぐパスワードを変更してください。`)
    } catch (error) {
      setMessage(`ログイン メールアドレスを元に戻す際に問題が発生しました。再度試してもメールアドレスを再設定できない場合は、管理者にお問い合わせください。`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleResetPassword = async (auth: Auth, oobCode: string) => {
    try {
      // リセットコードが有効か確認
      await verifyPasswordResetCode(auth, oobCode)
      setMessage("新しいパスワードを入力してください。")
    } catch (error: any) {
      // console.log(`パスワードリセットの確認に失敗しました: ${error.message}`)
      setMessage(`パスワードリセットの確認に失敗しました。`)
    } finally {
      setIsLoading(false)
    }
  }

  const sendPasswordChangeEmail = async () => {
    const auth = getAuth()
    setIsLoading(true)
    try {
      await sendPasswordResetEmail(auth, restoredEmail || '')
      setMessage(`${restoredEmail}に送信されたメールの手順に従ってパスワードを変更してください。`)
      setIsCompleted(true)
    } catch (error) {
      setMessage(`パスワード変更用メールの発行に失敗しました。再度やり直しても解決しない場合は、運営にお問い合わせください。`)
    } finally {
      setIsLoading(false)
    }
  }

  const handleConfirmPasswordReset = async () => {
    if (newPassword.length < 6) {
      setMessage('パスワードは6文字以上で入力してください。')
      return
    }

    const auth = getAuth()
    setIsLoading(true)

    try {
      await confirmPasswordReset(auth, oobCode, newPassword)
      setMessage("パスワードの変更が完了しました。引き続きアプリをご利用ください。")
      setPasswordResetSuccess(true)
    } catch (error: any) {
      // console.log(`パスワードのリセット中にエラーが発生しました: ${error.message}`)
      setMessage(`パスワードのリセット中にエラーが発生しました。`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Layout header>
      <Box p="20px" style={{ textAlign: "center" }}>
        {isLoading && <CircularProgress style={{ margin: '20px 0' }} />}
        <Box>
          {message && <Typography style={{ color: '#fff', margin: '20px 0', fontSize: '14px' }}>{message}</Typography>}
        </Box>
        {mode === "recoverEmail" && !isCompleted && <Button variant="contained" color="primary" onClick={sendPasswordChangeEmail}>パスワードを変更</Button>}
        {mode === "resetPassword" && !passwordResetSuccess && (
          <Box>
            <TextField
              fullWidth
              type="password"
              label="新しいパスワード"
              variant="outlined"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              style={{ marginBottom: '16px' }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleConfirmPasswordReset}
            // disabled={isLoading || newPassword.length < 6}
            >
              パスワードを変更
            </Button>
          </Box>
        )}
      </Box>
    </Layout>
  )
}

export default AppAccount
